@import "../../index.scss";

.landing {
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
  background: #00598e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #141414;

  & > .wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    max-height: 45vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    padding: 4% 0;
    position: relative;
    z-index: 1;
    background: white;
    border-radius: 3rem;
    padding: 1rem;
    aspect-ratio: 1.8/1;

    & > div {
      height: 100%;
    }

    & > .leftContainer {
      width: 50%;
      padding: 1.5rem 0;
      padding-left: 4rem;
      display: flex;
      justify-content: center;
    }

    & > .rightContainer {
      width: 50%;
      // background: var(--primary-color);
      border-radius: 2rem;
      position: relative;
      overflow: scroll;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
  max-height: 600px;
  width: 500px;
  overflow-y: scroll;

  & > .title {
    font-size: 2rem;
    font-weight: 500;
  }

  & > h3 {
    font-weight: 450;
    font-size: 1rem;

    & > span {
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
      padding-right: 5px;
    }
  }

  & > .credentialsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-width: 80%;

    & > .flexContainer {
      & > a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .divider {
    position: relative;
    font-size: 1rem;
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    // margin: 1.5rem 0;

    & > span {
      background: white;
      padding: 0 10px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      background: grey;
      z-index: -1;
      width: 100%;
    }
  }

  .flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > button {
    max-width: 80%;
  }

  & > :last-child:not(button) {
    cursor: not-allowed;
  }
}
