@import "../../index.scss";

.client {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;

  & > .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > .addClient {
      border: 1px solid var(--border-color);
      background: var(--primary-color);
      color: white;
      padding: 1rem 2rem;
      border-radius: 8px;
      padding: 1rem 2rem;
      margin-left: auto;
      width: max-content;
      cursor: pointer;
      outline: none;
    }
  }
}
