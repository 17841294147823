* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import "./assets/scss/variables";

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  // this is the default value below
  --toastify-color-progress-light: var(--primary-color);
}

body {
  font-family: "Rubik", sans-serif;
  color: var(--text-color);
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

section {
  height: 100vh;
  //   scroll-snap-align: center;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

h1 {
  font-size: 5rem;
  font-weight: 500;
}

h2 {
  font-size: 3.5rem;
}

h5 {
  font-size: 1.4rem;
  font-weight: 450;
}

a {
  all: unset;
}

// Map SCSS variables to CSS variables
:root {
  --primary-color: #{$dark-primary-color};
  --secondary-color: #{$secondary-color};
  --bg-color: #{$light-bg-color};
  --text-color: #{$text-color};
  --text-blue: #{$text-blue};
  --gray-text-color: #{$gray-text-color};
  --light-text-color: #{$light-text-color};
  --border-color: #{$border-color};
  --primary-red: #{$primary-red};
  --highlight-color: #{$dark-primary-color};
  --highlight-text-color: #{$highlight-text-color};
}

[data-theme="dark"] {
  --primary-color: #1e2022;
  --secondary-color: #34373b;
  --bg-color: #{$primary-color}; // You can change this as needed
  --text-color: #{$light-text-color};
  --text-blue: #{$text-blue}; // You can change this as needed
  --gray-text-color: #{#a8b3bc};
  --light-text-color: #{#a8b3bc};
  --border-color: #{$border-color};
  --primary-red: #{$primary-red}; // You can change this as needed
  --highlight-color: #b7d31e;
  --highlight-text-color: white;
}
