@import "../../index.scss";

.client {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;

  & > .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > .addClient {
      border: 1px solid var(--border-color);
      background: var(--primary-color);
      color: white;
      padding: 1rem 2rem;
      border-radius: 8px;
      margin-left: auto;
      width: max-content;
      cursor: pointer;
      outline: none;
    }
  }

  & > .tableContainer {
    height: calc(100% - 82px);
    overflow-y: scroll;
    background: var(--secondary-color);
    color: var(--gray-text-color);

    & > table {
      width: 100%;
      height: 100%;

      th,
      td {
        padding: 1rem;
        text-align: left;
      }

      thead {
        background: var(--primary-color);
        color: white;
      }

      tbody {
        overflow-y: scroll;
        height: calc(100% - 52px);

        tr {
          border: 1px solid var(--border-color);
          border-top: none;

          & > :nth-child(5),
          :nth-child(6) {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--gray-text-color);
            font-size: 1.2rem;
            cursor: pointer;
          }
        }

        & > div {
          border-bottom: 1px solid #e5e5e5;
        }

        & > :last-child {
          border: none;
        }
      }

      tr {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 100px 100px;
      }
    }
  }
}
