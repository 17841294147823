@import "../../index.scss";

.client {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;

  & > .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > .searchOption {
      padding: 1rem 1.5rem;
      border: 1px solid grey;
      min-width: 450px;
      border-radius: 15px;
      outline: none;

      &:focus {
        border: 1px solid var(--primary-color);
      }
    }

    & > .addClient {
      background: var(--primary-color);
      padding: 1rem 2rem;
      border-radius: 8px;
      margin-left: auto;
      width: max-content;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;
      border: 1px solid var(--border-color);
    }
  }

  & > .tableContainer {
    height: calc(100% - 82px);
    overflow-y: scroll;
    background: var(--secondary-color);
    color: var(--gray-text-color);

    & > table {
      width: 100%;
      height: 100%;
      border: 1px solid var(--border-color);
      border-top: none;

      th,
      td {
        padding: 1rem;
        text-align: left;
      }

      thead {
        background: var(--primary-color);
        color: white;
      }

      tbody {
        overflow-y: scroll;
        height: calc(100% - 52px);

        tr {
          & > :nth-child(5),
          :nth-child(6) {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--gray-text-color);
            font-size: 1.2rem;
            cursor: pointer;
          }
        }

        & > div {
          border-bottom: 1px solid #e5e5e5;
        }

        & > :last-child {
          border: none;
        }
      }

      tr {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 100px 100px;
      }
    }
  }

  .nextRemainder {
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    // inset: 0;

    & > .increaseContainer {
      background: white;
      // padding: 3rem;
      // border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      // position: absolute;
      width: 100%;
      // overflow-y: scroll;
      padding-left: 1rem;
      margin-top: 1.5rem;
      border-radius: 6px;

      & > .title {
        font-size: 1rem;
        font-weight: 500;
        color: var(--primary-color);
        text-align: left;
        width: 100%;
      }

      & > :nth-child(2) {
        margin: 1rem 0;
      }

      & > .addinventory {
        background: var(--primary-color);
        color: var(--text-color);
        padding: 1rem 2rem;
        border-radius: 10px;
        margin-left: auto;
        width: max-content;
        cursor: pointer;
        outline: none;
        border: none;
      }

      & > .inventoryFields {
        width: 325px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 100%;

        & > button {
          margin-top: 1rem;
        }

        & > .remainderDate {
          & > span {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
