@import "../../../index.scss";

.landing {
  position: relative;
  padding: 90px 0;
  overflow: hidden;
  background: rgb(0, 99, 142);
  background: linear-gradient(
    340deg,
    rgba(0, 99, 142, 1) 8%,
    rgba(255, 255, 255, 1) 100%
  );

  & > .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    padding: 4% 0;
    position: relative;
    z-index: 1;

    & > h1 {
      width: 65%;
      line-height: 1.4;

      & > :nth-child(2) {
        justify-content: center;
        padding-left: 2.5rem;
        display: flex;
        gap: 1rem;
        position: relative;

        & > div {
          display: flex;
          align-items: center;
          font-size: 0.7rem;
          position: absolute;
          height: 100%;
          left: 62.5%;
          width: 100px;
          color: var(--gray-text-color);
          cursor: pointer;

          &:hover {
            text-decoration: line-through;
          }
        }
      }
    }

    & > ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 2rem;
      line-height: 1.6;
      list-style: disc;

      & > li {
        margin-bottom: 1rem;
      }
    }

    & > div {
      height: 70vh;
      padding-bottom: 2%;
      position: absolute;
      right: -5rem;
    }
  }
}
