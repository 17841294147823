.dashboard {
  padding: 2rem 2.2rem;
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      & > .title {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 5px;
        color: var(--highlight-text-color);
      }

      & > .value {
        font-size: 2.5rem;
        font-weight: 500;
      }
    }
    
  }
}
