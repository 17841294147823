.caseContainer {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > .animalDetails {
    height: 6rem;
    // border: 1px solid red;
  }

  & > .allSteps {
    display: flex;
    align-items: center;
    height: 4rem;
    // border: 1px solid blue;
    gap: 1rem;

    & > div {
      padding: 0.65rem 1.5rem;
      border-radius: 35px;
      background: var(--primary-color);
      color: white;
      font-size: 0.9rem;
      cursor: pointer;
      position: relative;
    }

    & > .currentStep {
      background: var(--primary-color, 0.5);
    }

    & > :nth-child(1) {
      &::after {
        // content: "";
        // position: absolute;
        // background: #bbb9b9;
        // height: 100%;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // width: 1rem;
        // z-index: 1;
      }
    }

    & > :last-child {
    }
  }
}
