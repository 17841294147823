@import "../../../index.scss";

.services {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
  background: rgb(0, 99, 142);
  background: linear-gradient(
    170deg,
    rgba(0, 99, 142, 1) 8%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 50px;

  & > .leftCircle {
    background-color: transparent;
    width: 135vh;
    height: 135vh;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 80px var(--primary-color) inset,
      inset 0 0 80px var(--primary-color);
    animation: pulse 2s linear infinite alternate;
    position: absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -25%);
    z-index: 1;
  }

  & > .forWhom {
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--gray-text-color);

    & > h2 {
      margin-bottom: 1.5rem;
    }

    & > h5 {
      line-height: 2;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 5px 0;
      // margin-left: 1.5rem;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 40px var(--primary-color),
      inset 0 0 40px var(--secondary-color);
  }
  50% {
    box-shadow: 0 0 64px var(--secondary-color), inset 0 0 34px var(--bg-color);
  }
  100% {
    box-shadow: 0 0 34px var(--bg-color), inset 0 0 60px var(--primary-color);
  }
}
