.inventory {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;
  display: flex;

  & > .productsContainer {
    width: 50%;
    position: relative;
    padding-right: 2rem;
    border-right: 1px solid var(--border-color);

    & > .topBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      margin-top: 1rem;

      & > .title {
        font-size: 2rem;
        font-weight: 510;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    & > .allProducts {
      height: calc(100% - 4rem);
      background: var(--secondary-color);
      color: var(--gray-text-color);
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 6rem;
      overflow-y: scroll;
    }

    & > .totalBill {
      position: absolute;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      height: 4.2rem;
      bottom: 0;
      left: 0;
      width: calc(100% - 2rem);
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem 1rem;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

      & > .header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > .title {
          font-size: 1.5rem;
          font-weight: 510;
          color: var(--primary-color);
          text-align: center;

          & > :nth-child(2) {
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }

      & > .price {
        font-size: 1.2rem;
      }
    }
  }

  & > .cartContainer {
    width: 50%;
    padding-left: 2rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    & > .title {
      font-size: 2rem;
      font-weight: 510;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & > .inputDiv {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    & > .cartItems {
      height: calc(100% - 8rem);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;
    }

    & > .paymentBtn {
      font-size: 1rem;
      font-weight: 500;
      padding: 1rem 2rem;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border-radius: 8px;
      border: 1px soldi var(--border-color);
      color: white;
      background: var(--primary-color);
    }

    & > .notAllowedBtn {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .eachProduct {
    display: flex;
    gap: 1rem;

    & > .detailsContainer {
      width: calc(100% - 6rem);
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .imageContainer {
        height: 5rem;
        aspect-ratio: 0.9/1;
        border-radius: 6px;
        background: var(--primary-color);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      & > .details {
        & > .name {
          font-size: 1.1rem;
          margin-bottom: 8px;

          & > :nth-child(2) {
            color: var(--highlight-text-color);
            font-size: 0.9rem;
          }
        }

        & > .price {
          font-size: 1.25rem;
          font-weight: 510;
          display: flex;
          align-items: flex-end;
          gap: 7px;

          & > :nth-child(2) {
            font-size: 0.82rem;
            font-weight: 400;
            padding-bottom: 2.5px;
            text-decoration: line-through;
          }
        }
      }
    }

    & > .controlBtns {
      width: 6rem;
      display: flex;
      align-items: center;
      gap: 8px;

      & > svg {
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--primary-color);
      }

      & > input {
        width: 1.5rem;
        height: 100%;
        border: none;
        outline: none;
        text-align: center;
        font-weight: 520;
        font-size: 1.05rem;
      }

      & > .notAllowedBtn {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    & > .cartBtns {
      width: 16rem;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      padding: 0 1rem;

      & > .deleteBtn {
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        background: var(--primary-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all linear 0.1s;

        & > svg {
          font-size: 1.2rem;
          color: white;
        }

        &:hover {
          background: var(--primary-red);
        }
      }

      & > .eachTotal {
        font-weight: 400;
        display: flex;
        align-items: center;
        flex-direction: column;

        & > :nth-child(2) {
          font-size: 1.2rem;
          margin-top: 5px;
          font-weight: 500;
        }
      }
    }
  }
}
