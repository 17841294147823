@import "../../../index.scss";

.allTabs {
  padding: 1.5rem 0.25rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  // margin-bottom: 1rem;
  padding-top: 1rem;
  overflow-y: scroll;
  height: 100%;

  & > .subTabs {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);

    & > div {
      padding: 0.6rem 1.5rem;
      cursor: pointer;
      border-radius: 25px;
    }

    & > .selectedTab {
      color: white;
      background: var(--primary-color);
    }

    & > .mainBtns {
      display: flex;
      margin-left: auto;
      height: 100%;
      gap: 1rem;
      align-items: center;
      padding: 0;

      & > button {
        padding: 0.6rem 1.5rem;
        cursor: pointer;
        border-radius: 25px;
        color: var(--text-color);
        border: none;
        outline: none;
        background: var(--primary-color);
        color: white;
      }

      & > :nth-child(2) {
        background: var(--primary-red);
      }
    }
  }
}
