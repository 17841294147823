thead {
  background: var(--primary-color);
  position: sticky;
  top: 0;

  tr {
    th {
      color: white !important;
    }
  }
}
