.copyrights {
  color: var(--text-color);
  font-size: 1rem;
  text-align: center;
  width: 800px;
  margin: 0 auto;
  background: transparent;
  padding: 1rem 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
