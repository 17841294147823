@import "../../index.scss";

.navbar {
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  background: #ddd;
  transition: all linear 0.15s;

  & > .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    & > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    & > .logo {
      & > img,
      svg {
        height: 50%;
        width: max-content;
      }
    }

    & > .navigations {
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;
        gap: 2rem;

        & > button {
          all: unset;
          cursor: pointer;
          position: relative;
          transition: all linear 0.15s;

          &::before {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 1.5px;
            width: 0%;
            background: red;
          }

          &:hover {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
