@import "../../../index.scss";

.addDiagnosis {
  padding: 1rem;
  transition: all linear 0.3s;
  overflow-y: scroll;
  height: 100%;
  width: 100%;

  & > .addForm {
    padding-top: 1rem;

    & > .addBtn {
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      border-radius: 6px;
      color: white;
      border: none;
      outline: none;
      background: var(--primary-color);
      text-align: center;
      margin: auto;
      width: max-content;
    }

    & > .newForm {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      width: 100%;

      & > :nth-child(2),
      :last-child {
        & > :first-child {
          font-weight: unset;
        }

        textarea {
          min-width: 100%;
          max-width: 100%;
        }
      }

      & > .title {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      & > .btnsContainer {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        max-width: 500px;

        & > button {
          width: 50%;
        }
      }
    }
  }

  & > .oldDiagnosis {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;

    & > .eachDiagnosis {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      border: 1px solid var(--border-color);
      margin-top: 1rem;
      pointer-events: none;

      & > .title {
        font-size: 1.2rem;
        font-weight: 400;
      }

      & > :first-child,
      :last-child {
        & > :first-child {
          font-weight: unset;
        }
      }
    }
  }
}

.addDiagnosisForm {
  & > .title {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  & > .allDiagnosis {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    & > div {
      font-size: 0.9rem;
      padding: 0.6rem 1.5rem;
      border-radius: 35px;
      cursor: pointer;
      border: 1px solid var(--border-color);
      transition: all linear 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: var(--primary-color);
        color: var(--text-color);
      }
    }

    & > .selectedDiagnosis {
      background: var(--primary-color);
      color: white;
    }
  }
}
