.inventory {
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;
  display: flex;

  & > .productsContainer {
    width: 100%;
    position: relative;

    & > .topBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      & > .title {
        font-size: 2rem;
        font-weight: 510;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > .addinventory {
        background: var(--primary-color);
        padding: 1rem 2rem;
        border-radius: 8px;
        margin-left: auto;
        width: max-content;
        cursor: pointer;
        outline: none;
        border: none;
        font-weight: 510;
        border: 1px solid var(--border-color);
        color: white;
      }
    }

    & > .allProducts {
      // height: calc(100% - 4rem);
      background: var(--secondary-color);
      color: var(--gray-text-color);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
      padding-bottom: 4rem;
      max-height: calc(100% - 85px);
      overflow-y: scroll;
    }

    & > .increaseInventory {
      & > .increaseContainer {
        & > .title {
          font-size: 2rem;
          font-weight: 510;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
        }

        & > .inventoryFields {
          max-width: 500px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }

  .eachProduct {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // width: 45%;
    padding: 1rem;
    border-radius: 8px;

    & > .detailsContainer {
      width: calc(100% - 6rem);
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .imageContainer {
        height: 5rem;
        aspect-ratio: 0.9/1;
        border-radius: 6px;
        background: var(--primary-color);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      & > .details {
        & > .name {
          font-size: 1.1rem;
          margin-bottom: 8px;

          & > :nth-child(2) {
            color: var(--highlight-text-color);
            font-size: 0.9rem;
          }
        }

        & > .price {
          font-size: 1.25rem;
          font-weight: 510;
          display: flex;
          align-items: flex-end;
          gap: 7px;

          & > :nth-child(2) {
            font-size: 0.82rem;
            font-weight: 400;
            padding-bottom: 2.5px;
            text-decoration: line-through;
          }
        }
      }
    }

    & > .editBtn {
      padding: 0.6rem 1.5rem;
      cursor: pointer;
      font-size: 0.9rem;
      height: max-content;
      border-radius: 8px;
      color: white;
      background: var(--primary-color);
    }
  }
}
